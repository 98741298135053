import SoftButton from "components/SoftButton";

import { Card, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { PropTypes } from "prop-types";

const SourceForm = ({ data }) => {


    return (
        <Card>
            <TextField
                autoFocus
                required
                margin="dense"
                id="title"
                name="title"
                defaultValue={data?.title}
                label="Title"

                type="text"
                fullWidth
                variant="standard"
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="type"
                name="type"
                defaultValue={data?.type}
                label="Type"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="rule"
                name="rule"
                defaultValue={data?.rule}

                label="Rule"
                type="text"
                fullWidth
                variant="standard"
            />
            <TextField
                autoFocus
                required
                margin="dense"
                id="range"
                defaultValue={data?.range}
                name="range"
                label="Range"
                type="text"
                fullWidth
                variant="standard"
            />
            <FormControl fullWidth >
                <InputLabel id="status-label" sx={{ transform: 'translate(0, 1.5px) scale(0.75)', marginLeft: "10px" }}>
                    Status
                </InputLabel>
                <Select
                    labelId="status-label"
                    id="status"
                    name="status"
                    defaultValue={data?.status}
                    sx={{
                        '& .MuiSelect-root': {
                            padding: '10px',
                        },
                    }}
                >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                </Select>
            </FormControl>

        </Card>
    )
}
export default SourceForm;

SourceForm.defaultProps = {
    data: {},
    type: "new"
}

SourceForm.propTypes = {
    data: PropTypes.any,
    type: PropTypes.string
}