// context.js

import { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import ConnectionsModel from "Models/Connection";
import FormDialog from "components/Pop";
import LoginDialog from "components/Pop/login";
import SourceeModel from "Models/Sources";
import RoyalityModel from "Models/Royality";
import NewFormDialog from "components/NewDialog";
import Loading from "layouts/loading";
import UsersModel from "Models/Users";
import LimitRewards from "Models/LimitRewards";
import ProductModel from "Models/Products";
import BusinessTrans from "Models/BusinessTransaction";
import UserTransactionModel from "Models/Transactions";
import ConsultationModel from "Models/Consultations";
import Pages from "Models/Routes";
import RentModel from "Models/Rents";
import IncomeLog from "Models/Income";
import UserModel from "Models/Administrator";
import Notification from "Models/Notifications";
import RewardsModel from "Models/Rewards";
import PendingVerificationsModel from "Models/Verifications";
import UserAddressModel from "Models/UserAddress";
import UserKycModel from "Models/UserKycDetails";
const SoftUI = createContext(null);

SoftUI.displayName = "SoftUIContext";

// Knocial Dashboard React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "USER": {
      return { ...state, loading: false, user: new UserModel().fromJson(action.value) };
    }
    case "RENT": {
      return { ...state, loading: false, rent: new RentModel().fromArray(action.value) };
    }
    case "TOKEN": {
      return { ...state, loading: false, token: new BusinessTrans().fromArray(action.value) };
    }
    case "TRANSACTIONS": {
      return {
        ...state,
        loading: false,
        transactions: new UserTransactionModel().fromArray(action.value),
      };
    }
    case "PENDINGWITHDRAW": {
      return {
        ...state,
        loading: false,
        withdrawRequest: new UserTransactionModel().fromArray(action.value),
      };
    }
    case "LOADING": {
      return { ...state, loading: action.value };
    }
    case "CONNECTION": {
      return {
        ...state,
        loading: false,
        connection: new ConnectionsModel().fromArray(action.value),
      };
    }
    case "USERS": {
      return { ...state, loading: false, users: new UsersModel().fromArray(action.value) };
    }
    case "USERSDETAILS": {
      return {
        ...state,
        loading: false,
        userTab: 0,
        userAddress: new UserAddressModel().fromJson(action.value?.address),
        userKyc: new UserKycModel().fromJson(action.value?.kyc),
      };
    }
    case "USER_TAB": {
      return {
        ...state,
        loading: false,
        userTab: action.value,
      };
    }
    case "NOTIFICATIONS": {
      return {
        ...state,
        loading: false,
        notifications: new Notification().fromArray(action.value),
      };
    }
    case "CONSULTATION": {
      return {
        ...state,
        loading: false,
        consultation: new ConsultationModel().fromArray(action.value),
      };
    }
    case "PRODUCTS": {
      return { ...state, loading: false, products: new ProductModel().fromArray(action.value) };
    }
    case "VERIFICATIONS": {
      return {
        ...state,
        loading: false,
        verification: new PendingVerificationsModel().fromArray(action.value),
      };
    }
    case "SOURCES": {
      return { ...state, loading: false, sources: new SourceeModel().fromArray(action.value) };
    }
    case "INCOMES": {
      return { ...state, loading: false, incomes: new IncomeLog().fromArray(action.value) };
    }
    case "LIMITREWARDS": {
      return { ...state, loading: false, limitrewards: new LimitRewards().fromArray(action.value) };
    }
    case "REWARDS": {
      return { ...state, loading: false, rewards: new RewardsModel().fromArray(action.value) };
    }
    case "ROYALITY": {
      return { ...state, royality: new RoyalityModel().fromArray(action.value), loading: false };
    }
    case "ROUTES": {
      return { ...state, routes: new Pages().fromArray(action.value), loading: false };
    }
    case "DIALOG": {
      return { ...state, dialog: action.value, loading: false };
    }
    case "START_LOAD": {
      return { ...state, dialog: [], loading: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Knocial Dashboard React context provider
function KnocialControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    user: {},
    connection: [],
    users: [],
    sources: [],
    products: [],
    token: [],
    incomes: [],
    userKyc: {},
    userAddress: {},
    consultation: [],
    withdrawRequest: [],
    notifications: [],
    transactions: [],
    verification: [],
    rent: [],
    routes: [],
    limitrewards: [],
    rewards: [],
    royality: [],
    dialog: [],
    userTab: 0,
    loading: false,
  };
  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return (
    <SoftUI.Provider value={value}>
      {/* Conditionally render loader */}

      <FormDialog
        open={
          controller.dialog?.length > 0 &&
          controller.dialog?.[0]?.status !== "otp" &&
          controller.dialog?.[0]?.status !== "form"
        }
        setOpen={(v) => {
          setDialog(dispatch, []);
        }}
        data={controller.dialog[0]}
      />

      <LoginDialog
        open={controller.dialog.length > 0 && controller.dialog[0]?.status === "otp"}
        setOpen={(v) => {
          setDialog(dispatch, []);
        }}
        data={controller.dialog[0]}
      />
      <NewFormDialog
        open={controller?.dialog?.length > 0 && controller.dialog[0]?.status === "form"}
        setOpen={(v) => {
          setDialog(dispatch, []);
        }}
        data={controller.dialog[0]}
      />
      {children}
      <Loading condition={controller.loading} />
    </SoftUI.Provider>
  );
}

// Knocial Dashboard React custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error("useSoftUIController should be used inside the KnocialControllerProvider.");
  }

  return context;
}

KnocialControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setUser = (dispatch, value) => dispatch({ type: "USER", value });
const setProducts = (dispatch, value) => dispatch({ type: "PRODUCTS", value });
const setVerifications = (dispatch, value) => dispatch({ type: "VERIFICATIONS", value });
const setUserDetails = (dispatch, value) => dispatch({ type: "USERSDETAILS", value });
const setConnection = (dispatch, value) => dispatch({ type: "CONNECTION", value });
const setUsers = (dispatch, value) => dispatch({ type: "USERS", value });
const setSources = (dispatch, value) => dispatch({ type: "SOURCES", value });
const setIncomes = (dispatch, value) => dispatch({ type: "INCOMES", value });
const setConsultation = (dispatch, value) => dispatch({ type: "CONSULTATION", value });
const setTokens = (dispatch, value) => dispatch({ type: "TOKEN", value });
const setLimitRewrds = (dispatch, value) => dispatch({ type: "LIMITREWARDS", value });
const setRewrds = (dispatch, value) => dispatch({ type: "REWARDS", value });
const setRoyality = (dispatch, value) => dispatch({ type: "ROYALITY", value });
const setRoutes = (dispatch, value) => dispatch({ type: "ROUTES", value });
const setRent = (dispatch, value) => dispatch({ type: "RENT", value });
const setTransactions = (dispatch, value) => dispatch({ type: "TRANSACTIONS", value });
const setWithdrawRequest = (dispatch, value) => dispatch({ type: "PENDINGWITHDRAW", value });
const setLoading = (dispatch, value) => dispatch({ type: "LOADING", value });
const setNotification = (dispatch, value) => dispatch({ type: "NOTIFICATIONS", value });
const setDialog = (dispatch, value) => dispatch({ type: "DIALOG", value });
const startLoading = (dispatch, value) => dispatch({ type: "START_LOAD", value });
const setUserTab = (dispatch, value) => dispatch({ type: "USER_TAB", value });

export {
  KnocialControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setLimitRewrds,
  setRewrds,
  setUser,
  setConnection,
  setUsers,
  setWithdrawRequest,
  setRent,
  setConsultation,
  setProducts,
  setNotification,
  setTransactions,
  setSources,
  setUserDetails,
  setLoading,
  setRoutes,
  setDialog,
  setVerifications,
  setTokens,
  startLoading,
  setIncomes,
  setRoyality,
  setUserTab,
};
